.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 3rem;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: flex-end;
    width: 100%;
}

.wrapper>img {
    width: 25rem;
    justify-content: center;
    padding-left: 100px;
}
.left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 40px;
}
.left>:nth-child(1) {
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
}
.left>:nth-child(2) {
    font-size: 16px;
    /* text-transform: uppercase; */
    display: block;
}
.right{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 20px;
}
.right>:nth-child(1) {
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
}
.right>:nth-child(2) {
display: block;
}
.review{
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}
.testimonial{
    width: 100%;
}
.testiswiper{
padding: 2rem;
}
.testibody{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap:1rem;
    box-shadow: 0px 10px 30px -50px #7d7d7d;
}
.testibody>img{
    position: absolute !important;
    width: 3rem;
    top:-1.4rem;
    left: 45%;
}
.testibody>span:nth-of-type(1){
     align-items: center;
     font-size: 0.8rem;
     letter-spacing: 1px;
     margin-top: 20px;
}
.testibody>hr{
    height: 1px;
    width: 80%;
    background: rgb(127, 122, 122);
    border: none;
}
.testibody>span:nth-of-type(2){
    font-weight: 500;

}
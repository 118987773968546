.container{
    margin:50px 20px;
    padding: 30px;
    display: flex;
    justify-content: space-around;
}
.leftarea{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    margin-top: 50px;
}
.leftarea>:nth-child(1){
font-size: 20px;
text-transform: uppercase;
display: block;
font-weight: bold;
}
.leftarea>:nth-child(2){
    font-size: 15px;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}
.leftarea>:nth-child(3){
    font-size: 15px;
    font-weight: bold;
}
.leftarea>img{
    position:absolute;
    width:130px;
    margin-top: 50px;
    top:50px
}
.rightside{

}
.beforeafter{
    width: 30rem;
}
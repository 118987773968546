.container{
    display: flex;
    padding: 1.2rem;
  
}
.logo{
    display: flex;
    align-items: center;
    flex:2;
    gap:20px
}
.logo>img{
    width: 50px;
    height: 50px;
}
.logo>span{
    font-weight: 600;
}
.right{
    display: flex;
    gap:2rem;
    align-items: center;
    justify-content: center;
}
.menu{
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
}
.menu>li:hover{
    color:rgb(239, 8, 46);
    cursor: pointer;
}
.search{
    width:7rem;
    outline: none;
    border:none;
    padding: 2px;
    border-radius: 2px;
    height: 20px;
}
.cart{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
@media screen and (max-width:640px){
    /* .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background-color: white;
        color:black;
        flex-direction: column;
        padding: 1rem;
    } */
    .menu{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    input{
        display: none;
    }
    .bars{
        display: block;
    }
}
.container{
    padding: 0 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.container>:nth-child(1){
    position: absolute;
    width: 100px;
    left: 30%;
    top: -3rem
}
.produucts{
    display: grid;
    width: 90%; 
    grid-template-columns:  25%  auto;
}
.leftside{
   padding-right: 10px;

}
.productlist{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    font-weight: 500;
    font-size: 20px;
}
.menu >li:hover{
    color:rgb(247, 27, 64);
    cursor: pointer;
}
.list{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    height: 250px;
    overflow-y: scroll;
    gap:20px;
    justify-content: space-between;
}
.productlist{
    width: 12rem;
    background-color: white;
    position: relative;
    height: 7rem;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 15px;
   
}
.productlist >img{
    top:30px;
    width: 50px;
    height: 100px;
}.name{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.name >:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
}
.name >:nth-child(2){
    font-size: 0.7rem;  
}
.price{
    font-weight: bold;
    font-size: 30px;
}
.shop{
    font-size: 0.6rem;
    border: 1px solid black;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}
.containerfooter{
    width: 100%;

    display: flex;
    flex-direction:column;
}
.container {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction:column;
 
}
.footer{
    width: 100%;
    display: flex;
justify-content: space-between;}

hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color:white;
    margin-top: 1rem;
}
.footerleft {
    width: 20%;
    padding-left: 20px;
    float: left;

}
.footerright{
    width:80%;
    display: flex;
    column-gap: 20px;
    justify-content: space-between
}
.logo {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.logo>img {
    width: 2.5rem;
    height: 2.5rem;
}
.logo>span {
    font-weight: 600;
    font-size: 1rem;
} 
.blocks{
    width: 250px;

}

.detail {
    display: flex;
    flex-direction: column;
    /* width: inherit; */
    font-size: 14px;
    gap: 1rem;
    font-style: italic;
}
.detail>span:nth-of-type(1) {
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;

}
.line {
    display: flex;
    gap:1rem;
    align-items: center;
}
.line:hover{
    cursor: pointer;
}
.icon{
    width: 25px;
}

.copyRight{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 12px;
}





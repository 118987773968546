.container {
    padding: 0px 30px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.blueCircle {
    width: 25rem;
    height: 25rem;
    background-color: #F8E367;
    z-index: -99;
    border-radius: 50%;
    padding-right: 50px
}

.warpper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}

.warpper>img {
    width: 25rem;
    position: absolute;
    padding-right: 50px;
}

.cart2 {
    position: absolute;
    bottom: 30%;
    right: 5%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cart2>svg {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    border: 4px solid black;
}

.signup {
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: white;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
}

.signup>:first-child {
    display: block;
    width: 60px
}

.signup>:nth-child(2) {
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: center;

}

.containerleft,
.containerright {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1 {
    text-transform: uppercase;
    font-size: 20px;
    width: min-content;
    display: block;
    font-weight: 600;
    padding-left: 60px;
    padding-top: 20px;
}

.text2 {
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
    padding-left: 30px;
}

.text2>:first-child {
    font-weight: 800;
    font-size: 25px;
    /* padding-left: 60px; */
}

.text2>:nth-child(2) {
    display: block;
    /* padding-left: 30px; */
    /* padding-right: 60px; */
}

.traffic {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child {
    font-weight: 800;
    font-size: 2.5rem;
    padding-right: 60px;
}

.customer {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.customer>:first-child {
    font-weight: 800;
    text-align: right;
    font-size: 2.5rem;
    padding-right: 60px;
}
@media screen and (max-width:640px){
    .containerleft, .containerright{
        display: none;
    }
    .blueCircle{
        display:none;
       height: 25rem;
    }
    .warpper{
        width:330px
    }
}
.container{
 position: relative;
 margin-top: 40px;
}
.swiper{
    width:70%;
    height:170px;
    position:reset;
}
.swiperslide{
    background: white;
    display: flex;
    border-radius: 10px;
    padding:20px;
    box-sizing: border-box;
    overflow: relative;
    height: 170px;
}
.slideleft{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.name{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.name >:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
}
.name >:nth-child(2){
    font-size: 0.7rem;  
}
.price{
    font-weight: bold;
    font-size: 30px;
}
.shop{
    font-size: 0.6rem;
    border: 1px solid black;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}
/* .imagess{
    width: 20px;
    height: 20px;
} */